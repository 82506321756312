<template>
  <el-upload class="upload-file-turn-text"
             ref="upload"
             action="#"
             :limit="limit"
             :http-request="customUpload"
             accept=".xlsx"
             :file-list="fileList"
             :show-file-list="false"
             :on-exceed="handleExceed">
    <el-button :size="buttonSize" type="primary" :loading="loading">
      <i v-if="buttonIcon && !loading" :class="buttonIcon"></i>
      {{loading?'导入中':buttonText}}
    </el-button>
    <template #tip>
      <div v-if="tip" class="el-upload__tip">
        <i v-if="tipIcon" :class="tipIcon"></i>
        {{tip}}
      </div>
    </template>
  </el-upload>
</template>
<script>
import { importClass } from "../../api/school-class-list";
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Function,
    },
    //按钮文字
    buttonText: {
      type: String,
      default: "点击上传",
    },
    //按钮大小
    buttonSize: {
      type: String,
      default: "small",
    },
    //按钮图标
    buttonIcon: {
      type: String,
      default: "el-icon-upload2",
    },
    //提示文字
    tip: String,
    //提示文字图标
    tipIcon: String,
  },
  watch: {
    open(val) {
      if (!val) {
        this.$refs.upload.clearFiles();
      }
    },
  },
  data() {
    return {
      limit: 1, //限制文件数量
      fileList: [], //上传的文件数组，展示用
      loading: false, //转换中
    };
  },
  methods: {
    //文件限制超出
    handleExceed(file, fileList) {
      this.$message.error("最多可上传一个文件");
    },
    //自定义上传
    customUpload(uploadObj) {
      this.loading = true;
      const file = uploadObj.file;
      const name = file.name;
      if (this.fileList.length > 0) {
        this.fileList = [];
      }
      importClass(file)
        .then((res) => {
          if (res.data.status === 1) {
            this.$message.success(res.msg);
            this.fileList = [{ name }];
            this.update();
            this.loading = false;
          } else {
            this.loading = false;
            this.update();
            this.$msgbox
              .confirm(res.data.msg, res.msg, {
                type: "error",
                showCancelButton: false,
              })
              .then((res) => { console.log(res)})
              .catch((err) => {console.log(err)});
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-file-turn-text {
  ::v-deep .el-list-enter-active,
  ::v-deep .el-list-leave-active {
    transition: none;
  }

  ::v-deep .el-list-enter,
  ::v-deep .el-list-leave-active {
    opacity: 0;
  }
  .el-upload__tip {
    line-height: 1;
  }
  ::v-deep.el-upload-list__item {
    width: 202px;
  }
}

.el-upload-list {
  display: none;
}
ul {
  display: none;
}
</style>
