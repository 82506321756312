<template>
  <div class="member">
    <listLayout
      ref="ListLayout"
      :thead="thead"
      :on-fetch="getList"
      :filter-form="filterForm"
    >
      <template #top>
        <el-button style="margin-bottom: 10px" size="small" type="primary" @click="add">添加班级</el-button>
        <el-button style="margin-bottom: 10px" size="small" type="primary" @click="isShowImportClass = true">批量导入班级</el-button>
      </template>
      <template #filter>
        <ListFilter
          :page="pageData.current_page"
          :filterChange="onFilter"
          :exportData="filterForm"
          :selection="selection">
        </ListFilter>
      </template>
      <template #tabletop>
       <el-button style="margin-top: 20px" @click="deletedArr" type="danger" size="small">删除</el-button>
      </template>
      <!-- 操作 -->
      <template v-slot:operation="scope">
          <el-button type="text" size="small" @click="Edit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="Member(scope.row)">班级成员</el-button>
          <el-button  type="text" size="small">
            <el-dropdown @command="handleClickRow($event, scope.row)" >
              <span style="color: #3576ff;font-size:14px">更多</span>
              <el-dropdown-menu slot="dropdown">
              <!-- 转移 -->
                <el-dropdown-item command="classMove">学生数据转移</el-dropdown-item>
                <el-dropdown-item command="del">删除</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
        </el-button>
      </template>
    </listLayout>
    <!-- 批量导入班级 -->
    <ImportClass v-model="isShowImportClass" :updateList="reflashList"></ImportClass>
    <!-- 添加班级 -->
    <addClass v-model="isShowAdd" :updateList="reflashList" :ID="currentId" :selection="selection"></addClass>
    <!-- 编辑班级 -->
    <editClass v-model="isShowEdit" :updateList="reflashList" :formItem="form" :ID="currentId" :selection="selection"></editClass>
    <!-- 数据转移 -->
    <Move :updateList="reflashList" v-model="isShowMove" :selection="selection" :formItem="form"></Move>
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout';
import ListFilter from '../../components/class/ListFilter';
import Move from '../../components/class/Move';
import { ClassMembers, onExportMembers, Delete, AllClass } from '../../api/school-class-list';
import ImportClass from "../../components/class/ImportClass";
import addClass from "../../components/class/addClass";
import editClass from "../../components/class/EditClass";
import { searchClass } from "../../api/member/class-message";
import {getCampusAndPeriod, getEntranceYears} from '../../api/member/member-list'
export default {
  components: { ListLayout, ListFilter, ImportClass, addClass, Move, editClass},
  data() {
    return {
        thead: [
            { label: '勾选', type: 'selection', minWidth: 150},
            { label: '学段', prop: 'period_name', minWidth: 150},
            { label: '校区', prop: 'campus_name',minWidth: 150},
            { label: '毕业年份', prop: 'entrance_year',minWidth: 150},
            { label: '班级', prop: 'name', minWidth: 150},
            { label: '班主任', prop: 'teacher', minWidth: 150},
            { label: '学生总数', prop: 'students_count', minWidth: 150},
            { label: '未认证学生数', prop: 'non_ident_students_count', minWidth: 150},
            { label: '已认证学生数', prop: 'ident_students_count', minWidth: 150},
            { label: '操作', type: "operation", minWidth: 200},
        ],
        // 筛选对象
        filterForm: {
            id: '',
            page: 1,
            teacher: '',
            period_id: '',
            campus_id: '',
            page_size: 15,
            export_ids: []
        },
        pageData: {
            current_page: 1
        },
        isShowImportClass: false,
        isShowAdd: false,
        isShowEdit: false,
        isShowMove: false,
        currentId: 0,
        selection: {
          periodList: [],
          campusList: [],
          yearList: []
        },
        form: {},
        count: 0,
    };
  },
  methods: {
    getList(data) {
        return ClassMembers(data)
    },
    // 筛选回调
    onFilter(filterObj) {
      console.log(filterObj)
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.$refs.ListLayout.getList();
    },
    reflashList() {
      this.$refs.ListLayout.getList();
    },
    // 转移
    classMove(row) {
      this.form = row
      this.isShowMove = true
    },
    // 点击下拉菜单
    handleClickRow(value, row) {
      this[value](row);
      console.log(value,row)
    },
    // 点击编辑
    Edit(row) {
      this.form = row
      this.isShowEdit = true;
    },
    // 添加班级
    add() {
      this.isShowAdd = true
    },
    // 多删
    deletedArr() {
      const selectArr = this.$refs.ListLayout.selectArr
      const data = this.$refs.ListLayout.tbody
      for (let i = 0; i < selectArr.length; i++) {
        data.forEach(item => {
          if (item.id === selectArr[i]) {
            this.count += item.students_count
          }
       })
      }
      if (selectArr.length>0) {
        if (this.count) {
          this.$msgbox
            .confirm("部分班级下还有学生数据，建议先进行数据转移，再删除", "提示", {
              type: "error",
              confirmButtonText: "仍要删除",
              cancelButtonText: "取消",
            })
            .then((res) => {
              this.handleDelete({ id: selectArr.map(item => item) });
            }).catch(err => console.log(err))
        } else {
          this.$msgbox
            .confirm("确定要删除该数据吗？删除后无法恢复。", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: selectArr.map(item => item) });
            }).catch(err => console.log(err))
        }

      } else {
        this.$message.info('没有检测到勾选数据')
      }
    },
    // 点击删除
    del(row) {
      if (row.students_count) {
        this.$msgbox
        .confirm("班级下还有学生数据，建议先进行数据转移，再删除", "提示", {
          type: "error",
          confirmButtonText: "仍要删除",
          cancelButtonText: "取消",
        })
        .then((res) => {
          this.handleDelete({ id: row.id });
        }).catch(err => console.log(err))
      } else {
        this.$msgbox
        .confirm("确定要删除该数据吗？删除后无法恢复。", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete({ id: row.id });
        }).catch(err => console.log(err))
      }

    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      Delete(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.$refs.ListLayout.getList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 班级成员跳转
    Member(row) {
      console.log(row)
      this.$router.push({
        name: "studentList",
        params: { id: row.id },
      });
    },
    // 获取学段与校区id值
    getCampusAndPeriodData() {
      getCampusAndPeriod()
        .then((res) => {
          this.selection.periodList = res.data.periods;
          this.selection.campusList = res.data.campuses;
        })
        .catch((err) => console.log(err));
    },
    getEntranceYears() {
      getEntranceYears()
        .then((res) => {
          this.selection.yearList = res.data;
        })
        .catch((err) => console.log(err));
    },
  },    
  created() {
    this.getCampusAndPeriodData();
    this.getEntranceYears();
  }
};
</script>

<style lang="scss" scoped>

</style>