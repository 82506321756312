<template>
  <LayoutFilter
    :onFilter="onFilter"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
    :defaultCollapse="false"
  >
    <el-form
      :model="form"
      label-height="300px"
      label-width="80px"
      class="filter-from"
      ref="resetFilterForm"
    >
      <div class="filter-item">
        <el-form-item label="学段：" prop="period_id">
          <el-select v-model="form.period_id" placeholder="请选择">
            <el-option v-for="item in selection.periodList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校区：" prop="campus_id">
          <el-select v-model="form.campus_id" placeholder="请选择">
             <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业年份：" prop="entrance_year">
          <el-select v-model="form.entrance_year" placeholder="请选择">
             <el-option v-for="(item, index) in selection.yearList" :key="index" :label="item" :value="item
             "></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="班主任：" prop="teacher">
          <el-input v-model="form.teacher" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import { onExportMembers } from "../../api/school-class-list";
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    // 筛选
    filterChange: {
      type: Function,
      required: true,
    },
    // 导出
    exportData: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    selection: {
      type: Object
    }
  },
  data() {
    return {
      // form
      form: {
        period_name: "",
        campus_name: "",
        teacher: "",
        entrance_year: "",
      },
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["resetFilterForm"].resetFields();
      return this.onFilter();
    },
    //筛选
    onFilter() {
      // console.log(this.filterChange(Object.assign({}, this.form)))
      return this.filterChange(Object.assign({}, this.form));
    },
    // 导出
    onExport() {
      const postData = { ...this.exportData, page: this.page };
      return onExportMembers(postData)
        .then()
        .catch((err) => console.log(err));
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
