
<template>
  <el-dialog title="学生数据转移" :visible.sync="openDialog" width="500px" class="dialog-vertical">
    <el-form ref="postForm" :model="form" :rules="rules" class="filter-form" label-position="left">
        <el-form-item style="margin-left: 10px" class="filter-item" label="转出班级：" prop="period_id">
          <el-select v-model="formItem.name" disabled :placeholder="formItem.name">
          </el-select>
        </el-form-item>
        <div class="title">
          <span>转入班级，请先选择以下信息</span>
        </div>
        <div class="form-class">
          <el-form-item style="margin-left: 28px" label="学段：" prop="period_name">
            <el-select v-model="form.period_name" placeholder="请选择">
              <el-option v-for="item in selection.periodList"  :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 28px" label="校区：" prop="campus_name">
            <el-select v-model="form.campus_name" :loading="selectLoading" placeholder="请选择">
              <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="毕业年份：" prop="entrance_year">
            <el-select  :loading="selectLoading" v-model="form.entrance_year" placeholder="请选择">
              <el-option v-for="(item, index) in selection.yearList" :label="item.name" :key="index" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 28px" label="班级：" prop="class_id">
            <el-select v-model="form.class_id" :loading="selectLoading" placeholder="请选择">
              <el-option v-for="item in selection.ClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="sumbit('postForm')">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { move ,AllClass } from "../../api/school-class-list";
import { searchClass } from "../../api/member/class-message";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    formItem: {
      type: Object
    },
    updateList: {
      type: Function
    },
  },
  data() {
    return {
      form: {
        id: '',
        class_id: "",
        campus_name: "", // 校区
        period_name: "",
        entrance_year: "",
      },
      selectLoading: false,
      ClassList: [], // 全部班级
      rules: {
        period_name: [
          { required: true, validator: this.periodPass, trigger: 'change'}
        ],
        campus_name: [
          { required: true, validator: this.campusPass, trigger: 'change'}
        ],
        entrance_year: [
          { required: true, validator: this.yearPass, trigger: 'change'}
        ],
        class_id: [
          { required: true, validator: this.classIdPass, trigger: 'change'}
        ],
      },
      selection: {}
    };
  },
  methods: {
    getAllClass() {
      AllClass().then(res => {
        this.ClassList = res.data
      }).catch(err => console.log(err))
    },
    cancel() {
      this.openDialog = false;
      this.resetForm()
    },
    // 重置表单
    resetForm() {
      this.$refs['postForm'].resetFields();
    },
    sumbit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          move({
            id: this.formItem.id,
            target_id: this.form.class_id
          }).then(res => {
            this.$message.success(res.msg)
            this.openDialog = false
            this.updateList()
            this.resetForm()
          }).catch(err => {this.resetForm()})
        }
      });
    },
    // 名称验证
    classIdPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.class_id = ''
        } else if (this.form.entrance_year === '') {
          this.$message.error('请先选择毕业年份')
          this.form.class_id = ''
        } else if (this.form.campus_name === '') {
          this.$message.error('请先选择校区')
          this.form.class_id = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择班别")));
      }
    },
    // 学段验证
    periodPass(rule, value, callback) {
      if (value) {
          callback()
      } else {
        callback(callback(new Error("请选择学段")));
      }
    },
    // 校区校验
    campusPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.campus_name = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择校区")));
      }
    },
    // 年份校验
    yearPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.entrance_year = ''
        } else if (this.form.campus_name === '') {
          this.$message.error('请先选择毕业年份')
          this.form.entrance_year = ''
        }  else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择毕业年份")));
      }
    },
    getSearch(data) {
      this.selectLoading = true
      searchClass(data)
        .then((res) => {
          this.selection.periodList = res.data.period;
          this.selection.campusList = res.data.campus;
          this.selection.yearList = res.data.entrance_year;
          this.selection.ClassList = res.data.class;
          this.selectLoading = false
        })
        .catch((err) => console.log(err));
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  created() {
    this.getAllClass()
    this.getSearch()
  },
  watch: {
    form: {
      deep: true,
      handler(val, oldVal) {
        const data = {
          period_id: val.period_name,
          campus_id: val.campus_name,
          entrance_year: val.entrance_year,
        };
        this.getSearch(data);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
    .el-form {
      .title {
        margin:0 0 20px 9px;
      }
      .el-form-item {
          display: flex;
          justify-content: left;
      }
    }
}
</style>
