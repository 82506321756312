
<template>
  <el-dialog title="添加班级" :visible.sync="openDialog" width="500px" v-loading="loading" class="dialog-vertical">
    <el-form :model="form" :rules="rules" ref="postForm" label-position="right" @submit.native.prevent>
        <el-form-item label="学段：" prop="period_name">
          <el-select v-model="form.period_name" placeholder="请选择">
            <el-option v-for="item in selection.periodList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校区：" prop="campus_name">
          <el-select v-model="form.campus_name" placeholder="请选择">
             <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业年份：" prop="entrance_year">
          <el-date-picker value-format="yyyy" style="width: 201px;margin-right: 30px" v-model="form.entrance_year" type="year" placeholder="请选择"></el-date-picker>
        </el-form-item>
        <el-form-item label="班别：" prop="name">
            <el-input style="width: 201px" v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="毕业当届班主任：" prop="teacher">
            <el-input style="width: 201px;margin-right: 60px" v-model="form.teacher" placeholder="请输入"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="add('postForm')">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { addClass } from "../../api/school-class-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String]
    },
    selection: {
      type: Object
    },
    updateList: {
      type: Function
    }
  },
  data() {
    return {
      form: {
        id: 0,
        name: '',
        period_id: '', // 学段
        campus_id: '', // 校区
        teacher: '',
        entrance_year: null
      },
      rules: {
        name: [
          { required: true, validator: this.namePass, trigger: 'blur'}
        ],
        period_name: [
          { required: true, validator: this.periodPass, trigger: 'change'}
        ],
        campus_name: [
          { required: true, validator: this.campusPass, trigger: 'change'}
        ],
        entrance_year: [
          { required: true, validator: this.yearPass, trigger: 'change'}
        ],
      },
      loading: false,
    };
  },
  methods: {  
    add(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            id: this.form.id,
            name: this.form.name,
            period_id: this.form.period_name,
            campus_id: this.form.campus_name,
            entrance_year: this.form.entrance_year,
            teacher: this.form.teacher
          }
          addClass(data)
            .then(res => {
             this.resetForm()             
             this.loading = false
             this.openDialog = false
             this.updateList()
            })
            .catch(err => {
              this.loading = false
            })
          }
      });
    },
    close() {
      this.openDialog = false;
      this.resetForm()
    },
    // 名称验证
    namePass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请输入班别")));
      }
    },
    // 验证
    periodPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择学段")));
      }
    },
    campusPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择校区")));
      }
    },
    yearPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择毕业年份")));
      }
    },
    // 重置表单
    resetForm() {
      this.$refs['postForm'].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
    .el-form {
        .el-form-item {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
