import { render, staticRenderFns } from "./school-class-list.vue?vue&type=template&id=59f191ae&scoped=true&"
import script from "./school-class-list.vue?vue&type=script&lang=js&"
export * from "./school-class-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59f191ae",
  null
  
)

export default component.exports