
<template>
  <el-dialog title="编辑班级" :visible.sync="openDialog" width="500px" v-loading="loading" class="dialog-vertical">
    <el-form :model="formItem" :rules="rules"  ref="postForm" label-position="right">
        <el-form-item label="学段：" prop="period_id">
          <el-select v-model="formItem.period_name" :placeholder="formItem.period_name ? formItem.period_name:'请选择'">
            <el-option v-for="item in selection.periodList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校区：" prop="campus_id">
          <el-select v-model="formItem.campus_name" :placeholder="formItem.campus_name ? formItem.campus_name:'请选择'">
             <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业年份：" prop="entrance_year">
            <el-date-picker value-format="yyyy" style="width: 201px;margin-right: 30px" v-model="form.entrance_year" type="year" :placeholder="formItem.entrance_year+''"></el-date-picker>
        </el-form-item>
        <el-form-item label="班别：" prop="name">
            <el-input style="width: 201px" v-model="formItem.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="毕业当届班主任：" prop="teacher">
            <el-input style="width: 201px;margin-right: 60px" v-model="formItem.teacher" placeholder="请输入"></el-input>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="openDialog = false">取 消</el-button>
      <el-button type="primary" @click="add('postForm')">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { addClass } from "../../api/school-class-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String]
    },
    selection: {
      type: Object
    },
    formItem: {
      type: Object,
      default: {}
    },
    updateList: {
      type: Function
    }
  },
  data() {
    return {
      form: {
        id: 0,
        name: '',
        period_id: '', // 学段
        campus_id: '', // 校区
        teacher: '',
        entrance_year: ''
      },
      rules: {
        name: [
          { required: true, validator: this.namePass, trigger: 'blur'}
        ],
        period_id: [
          { required: true, validator: this.periodPass, trigger: 'change'}
        ],
        campus_id: [
          { required: true, validator: this.campusPass, trigger: 'change'}
        ],
        entrance_year: [
          { required: true, validator: this.yearPass, trigger: 'change'}
        ],
      },
      loading: false,
    };
  },
  methods: {
    add(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
            id: this.formItem.id,
            name: this.formItem.name,
            period_id: this.formItem.period_id,
            campus_id: this.formItem.campus_id,
            entrance_year: this.form.entrance_year || this.formItem.entrance_year,
            teacher: this.formItem.teacher
          }
          addClass(data)
            .then(res => {
             this.loading = false
             this.openDialog = false
             this.updateList()
            })
            .catch(err => {
              this.loading = false
            })
          }
      });
    },
    // 名称验证
    namePass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请输入班别")));
      }
    },
    periodPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择学段")));
      }
    },
    campusPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择校区")));
      }
    },
    yearPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择毕业年份")));
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        this.selection.periodList.forEach(item => {
          if (item.name == this.formItem.period_name) {
          return this.formItem.period_id = item.id
          }
        })
        this.selection.campusList.forEach(item => {
          if (item.name == this.formItem.campus_name) {
          return this.formItem.campus_id = item.id
          }
        })
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  watch: {
    formItem(val, oldval) {
      this.formItem = val
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
    .el-form {
        .el-form-item {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
